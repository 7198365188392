import { Button, Grid, Stack, Typography } from "@mui/material";
import { convertPxToRem } from "../../../../utils";
import ItemPill from "../ItemPill";
import { useRef } from "react";
import theme, { shadows } from "@/theme";
import { useTranslation } from "react-i18next";

interface ItemPillSliderProps {
  items: any[];
  onItemClick: (arg: any) => void;
  itemMap: Record<string, any>;
  maxItems?: number;
  displayViewAllButton?: boolean;
  onViewAllClick?: () => void;
  trackInteraction?: (arg: any) => void;
  ariaLabel?: string;
  shouldShowSubtitle?: boolean;
  shouldShowSecondarySubtitle?: boolean;
}

interface ScrollableContainer {
  isMouseDown: boolean;
  startX: number;
  scrollLeft: number;
}

export default function ItemPillSlider({
  onItemClick,
  items,
  itemMap,
  maxItems,
  displayViewAllButton,
  onViewAllClick,
  trackInteraction,
  ariaLabel,
  shouldShowSubtitle = false,
  shouldShowSecondarySubtitle = false,
}: ItemPillSliderProps) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement & Partial<ScrollableContainer>>(
    null
  );

  const handleMouseDown = (e: any) => {
    if (containerRef.current) {
      containerRef.current.isMouseDown = true;
      containerRef.current.startX = e.pageX - containerRef.current.offsetLeft;
    }
  };

  const handleMouseLeave = () => {
    if (containerRef.current) {
      containerRef.current.isMouseDown = false;
    }
  };

  const handleMouseUp = () => {
    if (containerRef.current) {
      containerRef.current.isMouseDown = false;
    }
  };

  const handleMouseMove = (e: any) => {
    if (!containerRef?.current?.isMouseDown) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - (containerRef.current.startX ?? 0)) * 2;
    containerRef.current.scrollLeft = containerRef.current.scrollLeft - walk;
  };

  function handleOnItemClick(item: any) {
    if (trackInteraction) {
      trackInteraction(item);
    }
    onItemClick(item);
  }

  return (
    <Stack
      ref={containerRef}
      sx={{
        display: "flex",
        overflowX: "auto",
        paddingLeft: 2,
        paddingBottom: 4,
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollBehavior: "smooth",
      }}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <Grid
        aria-label={ariaLabel}
        container
        spacing={2}
        wrap="nowrap"
        sx={{
          "& > :last-child": {
            paddingRight: 2,
          },
        }}
      >
        {items.slice(0, maxItems ?? items.length).map((item) => (
          <Grid item key={item.id || item.panelId}>
            <ItemPill
              onClick={() => handleOnItemClick(item)}
              selectedItem={item}
              itemMap={itemMap}
              titleStyles={{ fontSize: convertPxToRem(11), fontWeight: 600 }}
              shouldShowBody={false}
              shouldShowArrow={false}
              shouldShowSubtitle={shouldShowSubtitle}
              shouldShowSecondarySubtitle={shouldShowSecondarySubtitle}
            />
          </Grid>
        ))}

        {displayViewAllButton && onViewAllClick && (
          <Grid item>
            <Button
              variant="contained"
              color="info"
              aria-label={t("common.viewAll" as any)}
              sx={{
                justifyContent: "flex-start",
                flexDirection: "column",
                boxShadow: shadows.lightest,
                height: "100%",
                py: 1,
                px: 2,
                "&:hover, &:focus": {
                  boxShadow: shadows.lightest,
                  backgroundColor: theme.palette.primary[100],
                },
              }}
              fullWidth
              onClick={onViewAllClick}
            >
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                height="100%"
              >
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <Stack textAlign="left" style={{ marginTop: 0 }}>
                    <Typography
                      color="primary.main"
                      fontWeight="600"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      View all
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Button>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
