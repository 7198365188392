import CircledBackButton from "@/features/Common/components/CircledBackButton";
import LoadingSpinner from "@/features/Common/components/LoadingSpinner";
import {
  deleteMessage,
  Message,
  MessageDetailType,
  MessageStatus,
  MessageType,
  updateMessages,
} from "@/services/core-api-adapter";
import {
  convertPxToRem,
  formatDateToReadableString,
  formatDateToReadableTime,
} from "@/utils";
import { Button, Divider, Stack, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import useGetMessageDetails from "../../hooks/useGetMessageDetails";
import { ConfirmMessageDelete } from "../ConfirmMessageDelete";
import ContinueConsultMessageCard from "../ContinueConsultMessageCard";
import MessageDetailTypeIcon from "../Messages/components/MessageDetailTypeIcon";

export default function MessageDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { messageId } = useParams();

  const { message, messageFetchError, isMessageLoading } = useGetMessageDetails(
    { messageID: messageId || "" }
  );

  const cleanMessageContent = DOMPurify.sanitize(message?.message || "", {
    ALLOWED_ATTR: ["href", "target"],
  });

  const [isConfirmMessageDeleteModalOpen, setIsConfirmMessageDeleteModalOpen] =
    useState<boolean>(false);

  const [isDeleteMessageSuccess, setIsDeleteMessageSuccess] =
    useState<boolean>(false);

  const [isDeleteMessageError, setIsDeleteMessageError] =
    useState<boolean>(false);

  const [isDeleteMessageLoading, setIsDeleteMessageLoading] =
    useState<boolean>(false);

  function isMessageActionable(message: Message) {
    return (
      message.messageType === MessageType.CONSULT_CONTINUE ||
      message.messageType === MessageType.HEALTH_CHECK_REMINDER ||
      message.messageType === MessageType.DANGEROUS_VITAL_WARNING ||
      message.messageType === MessageType.PRODUCT_PRAISE ||
      message.messageType === MessageType.EDUCATION_AND_ENGAGEMENT ||
      message.messageType === MessageType.UNLIMITED_FACE_SCAN ||
      message.messageType === MessageType.PATHOLOGY_RESULTS
    );
  }

  const isConsultContinueMessage =
    message?.messageType === MessageType.CONSULT_CONTINUE;

  function onMessageCTAClick(message: Message) {
    if (
      message.messageType === MessageType.PRODUCT_PRAISE &&
      message.detailType === MessageDetailType.ACHIEVER_UNLOCK
    ) {
      return navigate("/home/get-medical-assistance/intercare");
    }

    switch (message.messageType) {
      case MessageType.UNLIMITED_FACE_SCAN:
      case MessageType.HEALTH_CHECK_REMINDER:
      case MessageType.PRODUCT_PRAISE:
        navigate("/binah-scan?skipScanPrompt=true");
        break;
      case MessageType.DANGEROUS_VITAL_WARNING:
      case MessageType.EDUCATION_AND_ENGAGEMENT:
        navigate("/home/get-medical-assistance/intercare");
        break;
      case MessageType.PATHOLOGY_RESULTS: {
        navigate(`/home/get-medical-assistance/pathology/results-breakout/`);
        break;
      }
      default:
        break;
    }
  }

  function onMessageDeleteSuccessClose() {
    navigate("/home/messages");
  }

  function onDeleteMessageButtonClick() {
    setIsConfirmMessageDeleteModalOpen(true);
  }

  function onMessageDelete() {
    if (message) {
      setIsDeleteMessageLoading(true);

      deleteMessage(message.id)
        .then(() => {
          setIsDeleteMessageSuccess(true);
        })
        .catch(() => {
          setIsDeleteMessageError(true);
        })
        .finally(() => {
          setIsDeleteMessageLoading(false);
        });
    }
  }

  function buildTimeStamp(date: string) {
    if (formatDateToReadableTime(date) !== "") {
      return `${formatDateToReadableTime(date)}, ${formatDateToReadableString(new Date(date))}`;
    } else {
      return formatDateToReadableString(new Date(date));
    }
  }

  function getMessageCTALabel(message: Message) {
    if (
      message.messageType === MessageType.PRODUCT_PRAISE &&
      message.detailType === MessageDetailType.ACHIEVER_UNLOCK
    ) {
      return t(
        `Messages.CTA.${MessageDetailType.ACHIEVER_UNLOCK}.label` as any
      );
    } else {
      return t(`Messages.CTA.${message.messageType}.label` as any);
    }
  }

  useEffect(() => {
    if (message && message.notificationStatus !== MessageStatus.READ) {
      updateMessages([
        { id: message.id, notificationStatus: MessageStatus.READ },
      ]);
    }
  }, [message]);

  return (
    <Stack
      p={2}
      sx={{
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <CircledBackButton
        label={t("MessageDetails.backButton.label")}
        ariaLabel={t("common.backButton")}
        showLabel={true}
      />

      {isMessageLoading && (
        <Stack position="relative" height="100%" alignSelf="center">
          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <LoadingSpinner />
          </Stack>
        </Stack>
      )}

      {!isMessageLoading && messageFetchError && (
        <Typography alignSelf="center">
          {t("common.somethingWentWrong")}
        </Typography>
      )}

      {!isMessageLoading && !messageFetchError && message && (
        <>
          <Stack
            spacing={4}
            p={2}
            mt={2}
            height="100%"
            justifyContent="space-between"
          >
            <Stack spacing={4}>
              <Stack
                flexDirection="row"
                gap={1}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  {message.detailType && (
                    <MessageDetailTypeIcon detailType={message.detailType} />
                  )}

                  <Typography
                    variant="h1"
                    fontWeight={600}
                    fontSize={convertPxToRem(26)}
                  >
                    {message?.title}
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ bgcolor: "grey.300" }} />
              <Typography variant="body2" color="neutral.light">
                {buildTimeStamp(message?.date)}
              </Typography>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: cleanMessageContent }}
              ></Typography>
            </Stack>

            <Stack
              direction={isConsultContinueMessage ? "column" : "row"}
              aria-label={t("Message.buttonGroup.label")}
              spacing={2}
            >
              {isConsultContinueMessage && (
                <ContinueConsultMessageCard message={message} />
              )}

              <Button
                fullWidth
                size="small"
                variant="outlined"
                onClick={onDeleteMessageButtonClick}
              >
                {t("MessageDetails.deleteMessageButton.label")}
              </Button>
              <>
                {!isConsultContinueMessage && isMessageActionable(message) && (
                  <Button
                    fullWidth
                    size="small"
                    onClick={() => onMessageCTAClick(message)}
                  >
                    {getMessageCTALabel(message)}
                  </Button>
                )}
              </>
            </Stack>
          </Stack>
          <ConfirmMessageDelete
            isLoading={isDeleteMessageLoading}
            isError={isDeleteMessageError}
            isSuccess={isDeleteMessageSuccess}
            isModalOpen={isConfirmMessageDeleteModalOpen}
            onMessageDelete={onMessageDelete}
            onMessageDeleteSuccessClose={onMessageDeleteSuccessClose}
            onModalClose={() => setIsConfirmMessageDeleteModalOpen(false)}
          />
        </>
      )}
    </Stack>
  );
}
