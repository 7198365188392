import {
  createPathologyOrder,
  getPathologyPanelById,
  getPathologyPanelsList,
  PathologyOrder,
  PathologyPanel,
  getPathologyOrderDetails,
} from "@/services/core-api-adapter";
import { assign, fromPromise, setup } from "xstate";

interface Context {
  fetchOrderDetailsResponse: PathologyOrder | null;
  createOrderResponse: PathologyOrder | null;
  bloodPanelListResponse: PathologyPanel[];
  bloodPanelDetailsResponse: any;
  selectedBloodPanel: PathologyPanel | null;
  panelIdQueryParam: string | null;
  orderIdQueryParam: string | null;
}

const initialContextValues: Context = {
  fetchOrderDetailsResponse: null,
  createOrderResponse: null,
  bloodPanelListResponse: [],
  bloodPanelDetailsResponse: {},
  selectedBloodPanel: null,
  panelIdQueryParam: null,
  orderIdQueryParam: null,
};

export const eventNames = {
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
  BUY_NOW_BUTTON_CLICKED: "BUY_NOW_BUTTON_CLICKED",
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  USER_REDIRECTED: "USER_REDIRECTED",
  BLOOD_PANEL_SELECTED: "BLOOD_PANEL_SELECTED",
  GO_HOME_BUTTON_CLICKED: "GO_HOME_BUTTON_CLICKED",
  VIEW_RECEIPT_BUTTON_CLICKED: "VIEW_RECEIPT_BUTTON_CLICKED",
  VIEW_RESULTS_BUTTON_CLICKED: "VIEW_RESULTS_BUTTON_CLICKED",
  HOW_TO_PREPARE_BUTTON_CLICKED: "HOW_TO_PREPARE_BUTTON_CLICKED",
};

export const pathologyFlowStateMachine = setup({
  types: {
    context: {} as Context,
  },
  actions: {
    addOrderDetailsToContext: function () {
      return {};
    },

    addBloodPanelListResponseToContext: assign(({ event }) => {
      return {
        bloodPanelListResponse: event.output,
      };
    }),

    addSelectedBloodPanelToContext: assign(({ event }) => {
      return {
        selectedBloodPanel: event.output,
      };
    }),

    addCreateOrderResponseToContext: assign(({ event }) => {
      return {
        createOrderResponse: event.output,
      };
    }),

    addOrderDetailsResponseToContext: assign(({ event }) => {
      return {
        fetchOrderDetailsResponse: event.output,
      };
    }),
  },
  actors: {
    getBloodPanelById: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const panelId =
          input?.context?.panelIdQueryParam ||
          input?.context.selectedBloodPanel?.panelId;
        return getPathologyPanelById(panelId || "");
      }
    ),
    getBloodPanelList: fromPromise(async () => {
      return getPathologyPanelsList();
    }),
    createOrder: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return createPathologyOrder(
          input?.context?.selectedBloodPanel?.panelId || ""
        );
      }
    ),
    fetchOrderDetails: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return getPathologyOrderDetails(
          input?.context?.orderIdQueryParam ||
            input?.context?.createOrderResponse?.orderId ||
            ""
        );
      }
    ),
  },
  guards: {
    hasBloodPanelIdInQueryParams: function ({ context }) {
      return context.panelIdQueryParam !== null;
    },
    hasOrderIdInQueryParams: function ({ context }) {
      return context.orderIdQueryParam !== null;
    },
    paymentHasFailed: function () {
      return true;
    },
    hasOrderNumber: function () {
      return true;
    },
    hasNoOrderNumber: function () {
      return true;
    },
    timeOutExceeded: function () {
      return true;
    },
  },
  schemas: {},
}).createMachine({
  context: initialContextValues,
  id: "pathologyFlow",
  initial: "showingBloodPanelListOrShowingBloodPanelDetails",
  states: {
    showingBloodPanelListOrShowingBloodPanelDetails: {
      entry: assign(({ event }) => {
        const entryContext = { ...initialContextValues };
        const { panelId = null, orderId = null } = event.input?.context
          ? event.input.context
          : {};
        return {
          ...entryContext,
          panelIdQueryParam: panelId,
          orderIdQueryParam: orderId,
        };
      }),
      always: [
        {
          target: "fetchingBloodPanelDetails",
          guard: {
            type: "hasBloodPanelIdInQueryParams",
          },
        },
        {
          target: "fetchingOrderDetails",
          guard: {
            type: "hasOrderIdInQueryParams",
          },
        },
        {
          target: "fetchingBloodPanelList",
        },
      ],
    },
    fetchingBloodPanelDetails: {
      invoke: {
        input: ({ context }) => ({ context }),
        onDone: {
          target: "showingBloodPanelDetails",
          actions: {
            type: "addSelectedBloodPanelToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "getBloodPanelById",
      },
    },
    fetchingBloodPanelList: {
      invoke: {
        input: {},
        onDone: {
          target: "showingBloodPanelList",
          actions: {
            type: "addBloodPanelListResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "getBloodPanelList",
      },
    },
    showingBloodPanelDetails: {
      on: {
        BACK_BUTTON_CLICKED: [
          {
            target: "exit",
            guard: {
              type: "hasBloodPanelIdInQueryParams",
            },
          },
          {
            target: "showingBloodPanelList",
          },
        ],
        BUY_NOW_BUTTON_CLICKED: {
          target: "showingPrompt",
        },
      },
    },
    error: {
      on: {
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    showingBloodPanelList: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "exit",
        },
        USER_REDIRECTED: {
          target: "exit",
        },
        BLOOD_PANEL_SELECTED: {
          target: "fetchingBloodPanelDetails",
          actions: { type: "addSelectedBloodPanelToContext" },
        },
      },
    },
    exit: {
      type: "final",
    },
    showingPrompt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingBloodPanelDetails",
        },
        CONTINUE_BUTTON_CLICKED: {
          target: "creatingOrder",
        },
      },
    },
    creatingOrder: {
      invoke: {
        input: ({ context }) => ({ context }),
        onDone: {
          target: "breakoutScreen",
          actions: {
            type: "addCreateOrderResponseToContext",
          },
        },
        src: "createOrder",
      },
    },
    breakoutScreen: {
      on: {
        USER_REDIRECTED: {
          target: "waitingForOrderOutcome",
        },
        BACK_BUTTON_CLICKED: {
          target: "showingPrompt",
        },
      },
    },
    waitingForOrderOutcome: {
      after: {
        "500": {
          target: "fetchingOrderDetails",
        },
      },
    },
    fetchingOrderDetails: {
      invoke: {
        id: "fetchOrderDetails",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "showingOrderSummary",
          actions: {
            type: "addOrderDetailsResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "fetchOrderDetails",
      },
    },
    paymentFailed: {},
    showingOrderSummary: {
      on: {
        VIEW_RECEIPT_BUTTON_CLICKED: {
          target: "showingPaymentReceipt",
        },
        VIEW_RESULTS_BUTTON_CLICKED: {
          target: "showingWhatsappBreakout",
        },
        HOW_TO_PREPARE_BUTTON_CLICKED: {
          target: "showingHowToPreparePrompt",
        },
        BACK_BUTTON_CLICKED: [
          {
            target: "exitToMyPurchases",
            guard: {
              type: "hasOrderIdInQueryParams",
            },
          },
          {
            target: "exit",
          },
        ],
      },
    },
    exitToMyPurchases: {
      type: "final",
    },
    showingWhatsappBreakout: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingOrderSummary",
        },
      },
    },
    showingPaymentReceipt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingOrderSummary",
        },
      },
    },
    showingHowToPreparePrompt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingOrderSummary",
        },
      },
    },
  },
});
