import { PathologyPanelChargeCode, Status } from "@/services/core-api-adapter";
import PathologyPanels from ".";

export default function PathologyPanelsExamples() {
  return (
    <PathologyPanels
      onStartDoctorConsultationClick={() => {
        console.log("On start doctor consultation click");
      }}
      onPanelClick={() => {
        console.log("On panel click");
      }}
      panelList={[
        {
          panelId: "00eb8796-ac37-496e-aecc-1fac68ed2211",
          panelName: "CD4 & Viral Load",
          amountTotal: 701.0,
          amountTotalFormatted: "R701.00",
          unuHandlingFeeAmountFormatted: "R42",
          unuTransactionalFeeAmountFormatted: "R9",
          panelTestsAmountFormatted: "R419",
          panelChargeCode: PathologyPanelChargeCode.CD4_VIRAL_LOAD,
          panelDescription:
            "This is the initial description of the Panel CD4 & Viral Load",
          status: Status.ACTIVE,
          orderIndex: 12,
          created: "2024-10-18T07:57:15.491446+00:00",
          panelPathologyTests: [
            {
              testName: "CD4",
              testAmount: 160.0,
              testCode: "CD4CD8",
            },
            {
              testName: "HIV VIRAL LOAD",
              testAmount: 541.0,
              testCode: "HIVVL",
            },
          ],
        },
      ]}
    />
  );
}
