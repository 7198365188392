import { Button, Divider, Link, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import theme, { overrideDefaultLinkStyling } from "@/theme";
import StepsCounter from "../../../Common/components/StepsCounter";
import DefaultAccordion from "@/features/Common/components/DefaultAccordion";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import { convertPxToRem } from "@/utils";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import { useState } from "react";
import FullScreenIframeDialog from "@/features/Common/components/FullScreenIframeDialog";
import DefaultDialog from "@/features/Common/components/DefaultDialog";
import {
  generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage,
  PathologyPanelChargeCode,
  requiresPreparationInstructions,
} from "@/services/core-api-adapter";
import IconLoader from "@/features/Common/components/IconLoader";

interface PathologyPromptProps {
  amountTotalFormatted: string;
  unuHandlingFeeAmountFormatted: string;
  unuTransactionalFeeAmountFormatted: string;
  panelTestsAmountFormatted: string;
  panelChargeCode: PathologyPanelChargeCode;
  onBackButtonClick: () => void;
  onContinueButtonClick: () => void;
}

interface PathologyPromptDisclaimerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function PathologyPromptDisclaimerModal({
  isOpen,
  onClose,
}: PathologyPromptDisclaimerModalProps) {
  const { t } = useTranslation();

  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: "Pathology prompt disclaimer modal",
    });
    trackUserInteraction({
      linkText: `Pathology prompt disclaimer | Terms and Conditions`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }
  return (
    <DefaultDialog
      label={t("PathologyPrompt.disclaimer.modal.title")}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <Stack textAlign="center" spacing={2}>
        <Typography variant="h3" fontWeight={600}>
          {t("PathologyPrompt.disclaimer.modal.title")}
        </Typography>

        <Typography variant="body2" color="inherit">
          {t("PathologyPrompt.disclaimer.modal.body")}
        </Typography>

        <Typography variant="body2" color="inherit">
          <Trans i18nKey="PathologyPrompt.disclaimer.modal.footer">
            <Link
              sx={{ color: "primary", fontWeight: 900 }}
              href="/"
              onClick={onTermsLinkClick}
            ></Link>
          </Trans>
        </Typography>
      </Stack>

      <FullScreenIframeDialog
        title={t(
          "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PLATFORM.documentTitle"
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage()}
      />
    </DefaultDialog>
  );
}

export function PathologyPrompt({
  amountTotalFormatted,
  unuHandlingFeeAmountFormatted,
  unuTransactionalFeeAmountFormatted,
  panelTestsAmountFormatted,
  panelChargeCode,
  onContinueButtonClick,
  onBackButtonClick,
}: PathologyPromptProps) {
  const { t } = useTranslation();

  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);

  const PATHOLOGY_FACILITY_LINK =
    getConfigurationVariable("VITE_APP_UNU_HEALTH_PATHOLOGY_FACILITY_LINK") ||
    "https://www.ampath.co.za/labs";

  function onFindAFacilityClick() {
    trackEvent({
      event: "action.pathologyFindAFacilityClicked",
      source: "Pathology next steps prompt",
    });
  }

  function onViewDisclaimerClick() {
    setIsDisclaimerModalOpen(true);
  }

  function onContinue() {
    trackEvent({
      event: "action.nextStepsContinue",
      source: "Pathology prompt",
    });
    onContinueButtonClick();
  }

  function onBack() {
    trackEvent({
      event: "action.nextStepsBack",
      source: "Pathology prompt",
    });
    onBackButtonClick();
  }

  return (
    <>
      <Stack p={2} justifyContent="space-between" flexGrow={1} minHeight="100%">
        <Stack
          color="neutral.50"
          spacing={4}
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h1" color="info.main" textAlign="center">
            {t("PathologyPrompt.title")}
          </Typography>
          <Stack spacing={4}>
            <Stack
              bgcolor="neutral.50"
              borderRadius={theme.shape.borderRadius}
              p={4}
              spacing={2}
            >
              <Typography variant="h2">
                {t("PathologyPrompt.steps.title")}
              </Typography>

              <Stack direction="row" alignItems={"center"}>
                <StepsCounter stepCounter={1} />
                <DefaultAccordion
                  title={t(`PathologyPrompt.steps.1.title`, {
                    amountTotalFormatted,
                  })}
                  aria-label={t(`PathologyPrompt.amountBreakdown.label`)}
                  key={t("PathologyPrompt.amountBreakdown.label")}
                  accordionStyles={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    width: "100%",
                    padding: "0",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="h4">
                      {t("PathologyPrompt.amountBreakdown.accordion.title")}
                    </Typography>
                    {panelTestsAmountFormatted && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body1" textTransform="none">
                          {t("PathologyPrompt.amountBreakdown.panel.fee.title")}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight="bold"
                        >
                          {panelTestsAmountFormatted}
                        </Typography>
                      </Stack>
                    )}
                    {unuHandlingFeeAmountFormatted && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body1" textTransform="none">
                          {t(
                            "PathologyPrompt.amountBreakdown.unuPlatform.fee.title"
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight="bold"
                        >
                          {unuHandlingFeeAmountFormatted}
                        </Typography>
                      </Stack>
                    )}
                    {unuTransactionalFeeAmountFormatted && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body1" textTransform="none">
                          {t(
                            "PathologyPrompt.amountBreakdown.unuTransactional.fee.title"
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight="bold"
                        >
                          {unuTransactionalFeeAmountFormatted}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </DefaultAccordion>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={2} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.2.title"} />
                </Typography>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={3} />
                <Stack ml={2} spacing={2}>
                  <Typography variant="body1">
                    <Trans i18nKey={"PathologyPrompt.steps.3.title"} />
                  </Typography>
                  <ButtonWithAnalytics
                    page="PathologyNextStepsPrompt"
                    intent="navigational"
                    text={t("PathologyPrompt.findAFacility.title")}
                    component="a"
                    variant="text"
                    role="link"
                    target="_blank"
                    size="small"
                    onClick={onFindAFacilityClick}
                    href={PATHOLOGY_FACILITY_LINK}
                    sx={{
                      ...overrideDefaultLinkStyling,
                      fontSize: convertPxToRem(12),
                      color: "primary.main",
                      justifyContent: "start",
                      padding: 0,
                    }}
                  >
                    {t("PathologyPrompt.findAFacility.title")}
                  </ButtonWithAnalytics>
                </Stack>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={4} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.4.title"} />
                </Typography>
              </Stack>

              {requiresPreparationInstructions(panelChargeCode) && (
                <>
                  <Divider />
                  <Stack direction="row">
                    <IconLoader
                      icon="InformationSymbolFilledCircleIcon"
                      sx={{
                        color: "primary.700",
                        fontSize: 30,
                      }}
                    />
                    <Typography ml={1} variant="body1">
                      <Trans
                        i18nKey={
                          "PathologyPrompt.preparation.requirements.title"
                        }
                      />
                    </Typography>
                  </Stack>
                </>
              )}
            </Stack>

            <ButtonWithAnalytics
              page="PathologyPrompt"
              text={t(`common.viewDisclaimer`)}
              intent="navigational"
              onClick={onViewDisclaimerClick}
              variant="text"
              color="info"
            >
              {t("common.viewDisclaimer")}
            </ButtonWithAnalytics>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={onBack}
                color="info"
                fullWidth
                variant="outlined"
              >
                {t("common.backButton")}
              </Button>
              <Button
                size="small"
                fullWidth
                component="a"
                target="_blank"
                color="info"
                onClick={onContinue}
              >
                {t("common.continueButton")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <FullscreenBackground color="primary.main" />
        <PathologyPromptDisclaimerModal
          isOpen={isDisclaimerModalOpen}
          onClose={() => setIsDisclaimerModalOpen(false)}
        />
      </Stack>
    </>
  );
}
