import { useNavigate } from "react-router";
import PathologyResultsBreakout from "../../components/PathologyResultsBreakout";
import { Stack } from "@mui/material";

export default function PathologyResultsBreakoutPage() {
  const navigate = useNavigate();

  function onBackButtonClick() {
    navigate(-1);
  }

  return (
    <Stack height="100vh" p={2}>
      <PathologyResultsBreakout onBackButtonClick={onBackButtonClick} />
    </Stack>
  );
}
