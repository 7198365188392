import { Box, Button, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { clampNumber, convertPxToRem } from "../../../../utils";
import theme from "../../../../theme";
import IconLoader from "../../../Common/components/IconLoader";
import useGetMemberships from "../../../Common/hooks/useGetMemberships";
import {
  MedicalServiceProviderType,
  getHealthScoreRating,
} from "../../../../services/core-api-adapter";
import useGetProductType from "@/features/Common/hooks/useGetProductType";

interface HealthScoreIndicatorProps {
  healthScore: number;
}

interface HealthScoreContainerSpacingProps {
  addContainerSpacing?: boolean;
  subtitleRightSpacing?: number;
}

interface HealthScoreExplainerContentProps {
  scoreKey: "poor" | "average" | "good";
  sxProps?: SxProps<Theme>;
}

type HealthScoreContainerProps = HealthScoreIndicatorProps &
  HealthScoreContainerSpacingProps;

enum HealthScoreRating {
  NONE = 0,
  LOWER_AVERAGE_SCORE = 33,
  UPPER_AVERAGE_SCORE = 66,
  GOOD = 100,
}

const healthScoreColorMap: any = {
  GOOD: theme.palette.accent1.main,
  AVERAGE: theme.palette.secondary[700],
  POOR: theme.palette.error.main,
};

function HealthScoreContainerSpacing() {
  return (
    <>
      <Box
        sx={{
          width: "0.5px",
          borderRadius: 2,
          background: (theme) => theme.palette.primary[300],
          height: "5px",
        }}
      />
      <Box
        sx={{
          width: ".5px",
          borderRadius: 2,
          background: (theme) => theme.palette.primary[300],
          height: "5px",
        }}
      />
    </>
  );
}

function HealthScoreContainer({
  healthScore,
  subtitleRightSpacing,
  addContainerSpacing = true,
}: HealthScoreContainerProps) {
  return (
    <>
      {addContainerSpacing && <HealthScoreContainerSpacing />}
      <Box
        sx={{
          width: "1.5px",
          borderRadius: 2,
          background: (theme) => theme.palette.primary[500],
          height: "6px",
          position: "relative",
        }}
      >
        <Typography
          component="p"
          variant="subtitle2"
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(1.5),
            right: convertPxToRem(subtitleRightSpacing || 0),
            color: (theme) => theme.palette.primary[500],
          }}
        >
          {healthScore}
        </Typography>
      </Box>
    </>
  );
}

function HealthScoreUnitScale() {
  return (
    <Stack justifyContent="space-between" direction="row">
      <HealthScoreContainer
        healthScore={HealthScoreRating.NONE}
        addContainerSpacing={false}
        subtitleRightSpacing={-5}
      />
      <HealthScoreContainer
        healthScore={HealthScoreRating.LOWER_AVERAGE_SCORE}
        subtitleRightSpacing={-5}
      />
      <HealthScoreContainer
        healthScore={HealthScoreRating.UPPER_AVERAGE_SCORE}
        subtitleRightSpacing={-5}
      />
      <HealthScoreContainer healthScore={HealthScoreRating.GOOD} />
    </Stack>
  );
}

function HealthScoreExplainerContent({
  scoreKey,
  sxProps,
}: HealthScoreExplainerContentProps) {
  const { t } = useTranslation();

  return (
    <Stack p={2} spacing={2}>
      <Typography textAlign="center" variant="h4" sx={{ ...sxProps }}>
        {t(`HealthProfile.healthScoreExplainer.${scoreKey}.title` as any)}
      </Typography>
      <Typography textAlign="center" variant="body2" sx={{ ...sxProps }}>
        {t(`HealthProfile.healthScoreExplainer.${scoreKey}.subtitle` as any)}
      </Typography>
    </Stack>
  );
}

export default function HealthScoreIndicator({
  healthScore,
}: HealthScoreIndicatorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const computedHealthScore = clampNumber(healthScore, 0, 100);
  const healthScoreRating = getHealthScoreRating(healthScore);
  const { medicalAssistanceProvider } = useGetMemberships();

  const { isPAYGProduct } = useGetProductType();
  const isHaveANurseCallMe =
    medicalAssistanceProvider === MedicalServiceProviderType.CALL_ME_BACK;

  function onMedicalAssistanceButtonClick() {
    if (isPAYGProduct) {
      return navigate("/home/get-medical-assistance/intercare");
    }

    navigate("/home/get-medical-assistance");
  }

  function renderMedicalAssistanceLabel() {
    if (isPAYGProduct) {
      return t("Home.NeedMedicalAssistanceCTA");
    }

    if (isHaveANurseCallMe) {
      return t("common.haveANurseCallMe");
    }

    return t("HealthProfile.healthScoreExplainer.buttonLabel");
  }

  function getHealthScoreExplainer(
    healthScoreRating: "GOOD" | "AVERAGE" | "POOR"
  ) {
    switch (healthScoreRating) {
      case "GOOD":
        return (
          <Stack bgcolor="accent1.main" borderRadius={2}>
            <HealthScoreExplainerContent
              sxProps={{
                color: (theme: Theme) => theme.palette.neutral.contrastText,
              }}
              scoreKey="good"
            />
          </Stack>
        );
      case "AVERAGE":
        return (
          <Stack
            bgcolor="secondary.700"
            sx={{
              borderRadius: 2,
            }}
          >
            <HealthScoreExplainerContent scoreKey="average" />
            <Button
              color="info"
              onClick={() => onMedicalAssistanceButtonClick()}
              style={{
                borderRadius: "0 0 8px 8px",
                border: `1.5px solid ${theme.palette.secondary[700]}`,
              }}
              startIcon={<IconLoader icon="ChatBubblePlusIcon" />}
            >
              {renderMedicalAssistanceLabel()}
            </Button>
          </Stack>
        );
      case "POOR":
        return (
          <Stack
            bgcolor="primary.main"
            sx={{
              borderRadius: 2,
            }}
          >
            <HealthScoreExplainerContent
              sxProps={{
                color: (theme: Theme) => theme.palette.neutral.contrastText,
              }}
              scoreKey="poor"
            />

            <Button
              color="info"
              onClick={() => onMedicalAssistanceButtonClick()}
              style={{
                borderRadius: "0 0 8px 8px",
                border: `1.5px solid ${theme.palette.primary.main}`,
              }}
              variant="contained"
              startIcon={<IconLoader icon="ChatBubblePlusIcon" />}
            >
              {renderMedicalAssistanceLabel()}
            </Button>
          </Stack>
        );
    }
  }

  return (
    <Stack
      component="article"
      spacing={4}
      aria-label={t("HealthScoreIndicator.panel.label")}
    >
      <Stack justifyContent="space-between">
        <Typography fontWeight={600}>
          {t("HealthScoreIndicator.title")}
        </Typography>

        <Stack direction="row" my={1}>
          <Typography
            aria-label="healthScore"
            fontWeight={800}
            sx={{ color: healthScoreColorMap[healthScoreRating] }}
          >{`${computedHealthScore}`}</Typography>
          <Typography>/100</Typography>
        </Stack>
      </Stack>

      <Stack spacing={6}>
        <Box>
          <Stack direction="row" position="relative" width="100%">
            <Box
              height={10}
              flexGrow={1}
              borderRadius={"5px 0 0 5px"}
              bgcolor="error.main"
            />
            <Box height={10} flexGrow={1} bgcolor="secondary.main" />
            <Box
              height={10}
              flexGrow={1}
              borderRadius="0px 5px 5px 0px"
              bgcolor="accent1.main"
            />
            <Box
              position="absolute"
              sx={{
                transform: `translateX(${convertPxToRem(-18)})`,
                bottom: (theme) => theme.spacing(1.5),
                height: convertPxToRem(29),
                left: `${computedHealthScore}%`,
              }}
            >
              <IconLoader
                icon="HealthPin"
                sx={{
                  fontSize: convertPxToRem(38),
                  color: healthScoreColorMap[healthScoreRating],
                }}
              />
            </Box>
          </Stack>
          <HealthScoreUnitScale />
        </Box>
        {getHealthScoreExplainer(healthScoreRating)}
      </Stack>
    </Stack>
  );
}
