import { PathologyPanelChargeCode } from "@/services/core-api-adapter";
import { PathologyPrompt } from ".";

export default function PathologyPromptExamples() {
  return (
    <>
      <PathologyPrompt
        amountTotalFormatted={"R134"}
        unuHandlingFeeAmountFormatted="R90"
        unuTransactionalFeeAmountFormatted="R44"
        panelTestsAmountFormatted=""
        panelChargeCode={PathologyPanelChargeCode.CD4_VIRAL_LOAD}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        onBackButtonClick={() => console.log("onBackButtonClick")}
      />
      <PathologyPrompt
        amountTotalFormatted={"R134"}
        unuHandlingFeeAmountFormatted="R90"
        unuTransactionalFeeAmountFormatted="R44"
        panelTestsAmountFormatted=""
        panelChargeCode={PathologyPanelChargeCode.CHOLESTEROL_MONITORING}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        onBackButtonClick={() => console.log("onBackButtonClick")}
      />
    </>
  );
}
