import { useGlobalStore } from "@/store";

export default function useGetProductType() {
  const { state } = useGlobalStore();

  function isPAYGProduct() {
    return state.currentUser.isRetailMember;
  }

  return {
    isPAYGProduct: isPAYGProduct(),
  };
}
