import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import CircledBackButton from "@/features/Common/components/CircledBackButton";
import {
  PathologyPanel,
  PathologyPanelChargeCode,
} from "@/services/core-api-adapter";
import { defaultFullscreenPageStyling } from "@/theme";
import { convertPxToRem } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PanelScreeningInfoAccordion from "../PanelScreeningInfoAccordion";
import PanelTestInfoAccordion from "../PanelTestInfoAccordion";
import PathologyRiskInfoAccordion from "../PathologyRiskInfoAccordion";
import IconLoader from "@/features/Common/components/IconLoader";
import { trackEvent, trackPageViewV2 } from "@/services/analytics-adapter";
import { getConfigurationVariable } from "@/services/configuration-adapter";

const PATHOLOGY_FACILITY_LINK =
  getConfigurationVariable("VITE_APP_UNU_HEALTH_PATHOLOGY_FACILITY_LINK") ||
  "https://www.ampath.co.za/labs";

interface PathologyPanelDetailsProps {
  onBuyButtonClick: () => void;
  panel: PathologyPanel;
  onBackButtonClick?: () => void;
}

export default function PathologyPanelDetails({
  onBuyButtonClick,
  panel,
  onBackButtonClick,
}: PathologyPanelDetailsProps) {
  const { t } = useTranslation();

  const [panelSections, setPanelSections] = useState<any | null>(null);

  enum PanelDetailSections {
    PANEL_TEST_INFO = "PANEL_TEST_INFO",
    ABOUT_CONDITION = "ABOUT_CONDITION",
    CONDITION_DANGERS = "CONDITION_DANGERS",
    WHO_IS_AT_RISK = "WHO_IS_AT_RISK",
    CONDITION_SYMPTOMS = "SYMPTOMS",
    ABOUT_CONDITION_ADDITIONAL = "ABOUT_CONDITION_ADDITIONAL",
    WHY_DO_TEST = "WHY_DO_TEST",
  }

  function onBuyNowButtonClick() {
    trackEvent({
      event: "action.pathologyPanelBuyNowButtonClicked",
      source: "Pathology panel details",
      panelName: panel.panelName,
    });
    onBuyButtonClick();
  }

  function buildPanelDetailSections(panelChargeCode: string) {
    let panelDetailSections: any = null;

    switch (panelChargeCode) {
      case PathologyPanelChargeCode.ANAEMIA:
        panelDetailSections = [
          PanelDetailSections.PANEL_TEST_INFO,
          PanelDetailSections.ABOUT_CONDITION,
          PanelDetailSections.WHO_IS_AT_RISK,
          PanelDetailSections.CONDITION_SYMPTOMS,
          PanelDetailSections.CONDITION_DANGERS,
        ];
        break;

      case PathologyPanelChargeCode.ANTENATAL:
        panelDetailSections = [
          PanelDetailSections.PANEL_TEST_INFO,
          PanelDetailSections.WHY_DO_TEST,
        ];
        break;

      case PathologyPanelChargeCode.HIV_MONITORING:
        panelDetailSections = [
          PanelDetailSections.PANEL_TEST_INFO,
          PanelDetailSections.ABOUT_CONDITION,
          PanelDetailSections.ABOUT_CONDITION_ADDITIONAL,
          PanelDetailSections.WHY_DO_TEST,
        ];
        break;

      case PathologyPanelChargeCode.HIV_SCREENING:
      case PathologyPanelChargeCode.SYPHILIS:
      case PathologyPanelChargeCode.HEPATITIS_B:
        panelDetailSections = [
          PanelDetailSections.PANEL_TEST_INFO,
          PanelDetailSections.ABOUT_CONDITION,
          PanelDetailSections.CONDITION_SYMPTOMS,
          PanelDetailSections.CONDITION_DANGERS,
        ];
        break;

      case PathologyPanelChargeCode.BLOOD_PRESSURE_MONITORING:
      case PathologyPanelChargeCode.CHOLESTEROL_MONITORING:
      case PathologyPanelChargeCode.DIABETES_MONITORING:
        panelDetailSections = [
          PanelDetailSections.PANEL_TEST_INFO,
          PanelDetailSections.WHO_IS_AT_RISK,
          PanelDetailSections.CONDITION_SYMPTOMS,
          PanelDetailSections.CONDITION_DANGERS,
        ];
        break;

      default:
        panelDetailSections = [
          PanelDetailSections.PANEL_TEST_INFO,
          PanelDetailSections.CONDITION_SYMPTOMS,
          PanelDetailSections.CONDITION_DANGERS,
        ];
        break;
    }
    return panelDetailSections;
  }

  useEffect(() => {
    if (panel?.panelChargeCode) {
      setPanelSections(buildPanelDetailSections(panel.panelChargeCode));
    }
  }, [panel]);

  useEffect(() => {
    trackPageViewV2({
      pageName: `PathologyPanelDetails | ${panel?.panelChargeCode}`,
      pageSubSection1: "Pathology",
      pageSubSection2: "PathologyPanelDetails",
      pageCategory: "Pathology",
    });
  }, []);

  function onFindAFacilityClick() {
    trackEvent({
      event: "action.pathologyPanelFindAFacilityClicked",
      source: "Pathology panel details",
      panelName: panel.panelName,
    });
  }

  return (
    <Stack
      spacing={2}
      minHeight="100vh"
      sx={{
        ...defaultFullscreenPageStyling,
        paddingBottom: convertPxToRem(100),
      }}
      position="relative"
    >
      <Stack>
        <CircledBackButton onClick={onBackButtonClick} showLabel />
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h2" component="h1">
          <Trans
            i18nKey={
              `PathologyPanelDetails.${panel?.panelChargeCode}.title` as any
            }
          >
            <Typography variant="body1"></Typography>
          </Trans>
        </Typography>
      </Stack>
      <ButtonWithAnalytics
        page={`PathologyPanelDetails | ${panel?.panelChargeCode}`}
        text={t("PathologyPanelDetails.findAFacility")}
        intent="confirmational"
        component="a"
        role="link"
        target="_blank"
        onClick={onFindAFacilityClick}
        href={PATHOLOGY_FACILITY_LINK}
        variant="outlined"
        size="small"
      >
        {t("PathologyPanelDetails.findAFacility")}
      </ButtonWithAnalytics>

      {panelSections?.includes(PanelDetailSections.PANEL_TEST_INFO) && (
        <Stack>
          <PanelTestInfoAccordion panelData={panel} />
        </Stack>
      )}

      <Stack>
        <Typography variant="body1" component="h2" fontWeight="bold">
          {t("PathologyPanelDetails.screeningInformation.title")}
        </Typography>
      </Stack>

      {panelSections?.includes(PanelDetailSections.ABOUT_CONDITION) && (
        <Stack>
          <PanelScreeningInfoAccordion
            panelData={panel}
            icon="MagnifyingGlassCircleIcon"
            screeningInfoType="aboutCondition"
          />
        </Stack>
      )}

      {panelSections?.includes(
        PanelDetailSections.ABOUT_CONDITION_ADDITIONAL
      ) && (
        <Stack>
          <PanelScreeningInfoAccordion
            panelData={panel}
            icon="MagnifyingGlassCircleIcon"
            screeningInfoType="aboutConditionAdditional"
          />
        </Stack>
      )}

      {panelSections?.includes(PanelDetailSections.WHY_DO_TEST) && (
        <Stack>
          <PanelScreeningInfoAccordion
            panelData={panel}
            icon="WavyPlusCircleIcon"
            screeningInfoType="whyDoTest"
          />
        </Stack>
      )}

      {panelSections?.includes(PanelDetailSections.WHO_IS_AT_RISK) && (
        <Stack>
          <PathologyRiskInfoAccordion panelData={panel} />
        </Stack>
      )}

      {panelSections?.includes(PanelDetailSections.CONDITION_SYMPTOMS) && (
        <Stack>
          <PanelScreeningInfoAccordion
            panelData={panel}
            icon="PageWithWritingCircleIcon"
            screeningInfoType="conditionSymptoms"
          />
        </Stack>
      )}

      {panelSections?.includes(PanelDetailSections.CONDITION_DANGERS) && (
        <Stack>
          <PanelScreeningInfoAccordion
            panelData={panel}
            icon="WarningCircleIcon"
            screeningInfoType="conditionDangers"
          />
        </Stack>
      )}

      <Stack
        position="fixed"
        display="flex"
        flexDirection="row"
        sx={{
          width: "100%",
          left: 0,
          bottom: 0,
          backgroundColor: "primary.main",
          height: convertPxToRem(80),
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          borderTopLeftRadius: convertPxToRem(16),
          borderTopRightRadius: convertPxToRem(16),
        }}
      >
        <Stack>
          <Typography
            variant="h1"
            component="h3"
            color="primary.contrastText"
            fontWeight="bold"
          >
            {panel?.amountTotalFormatted}
          </Typography>
        </Stack>
        <Stack>
          <ButtonWithAnalytics
            startIcon={<IconLoader icon="TrolleyIcon" />}
            page={`PathologyPanelDetails | ${panel?.panelChargeCode}`}
            text={t(`common.cancelButton`)}
            intent="confirmational"
            onClick={onBuyNowButtonClick}
            color="info"
            variant="contained"
          >
            {t("PathologyPanelDetails.buyNowButton")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>
    </Stack>
  );
}
