import ItemPillSlider from ".";
import {
  DirectToDoctorPrimarySymptom,
  DirectToDoctorPrimarySymptomOption,
  PathologyPanel,
  PathologyPanelChargeCode,
  Status,
} from "../../../../services/core-api-adapter";
import { Stack, Typography } from "@mui/material";
import {
  PATHOLOGY_PANEL_PILL_MAP,
  PRIMARY_SYMPTOM_PILL_MAP,
} from "../../constants";

const primarySymptoms: DirectToDoctorPrimarySymptom[] = [
  {
    id: "ee15c3f0-a2fc-4c9c-8a80-2c60fcf77823",
    name: DirectToDoctorPrimarySymptomOption.SKIN_CONDITIONS,
    friendlyName: "SKIN_CONDITIONS",
    status: Status.ACTIVE,
  },
  {
    id: "d24afc9c-03b2-474c-b710-0b46e57b4bc1",
    name: DirectToDoctorPrimarySymptomOption.EYE_INFECTIONS,
    friendlyName: "EYE_INFECTIONS",
    status: Status.ACTIVE,
  },
  {
    id: "f5bc7cfd-9283-4033-bcd1-842dae372530",
    name: DirectToDoctorPrimarySymptomOption.BLADDER_INFECTION,
    friendlyName: "BLADDER_INFECTION",
    status: Status.ACTIVE,
  },
  {
    id: "dae0c139-2fac-44a0-bdff-a8f3b0624d36",
    name: DirectToDoctorPrimarySymptomOption.COLD_FLU,
    friendlyName: "COLD_FLU",
    status: Status.ACTIVE,
  },
  {
    id: "7697221a-5e92-4a13-8a77-40df6cf5a9a7",
    name: DirectToDoctorPrimarySymptomOption.VOMITING_DIARRHEA,
    friendlyName: "VOMITING_DIARRHEA",
    status: Status.ACTIVE,
  },
  {
    id: "896c7a91-5ef5-49fe-b3f4-4eacaa91b206",
    name: DirectToDoctorPrimarySymptomOption.HEADACHE,
    friendlyName: "HEADACHE",
    status: Status.ACTIVE,
  },
  {
    id: "fa2dfc8f-8727-446f-a063-556d16e737db",
    name: DirectToDoctorPrimarySymptomOption.BACK_PAIN,
    friendlyName: "BACK_PAIN",
    status: Status.ACTIVE,
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: DirectToDoctorPrimarySymptomOption.CONTRACEPTION,
    friendlyName: "CONTRACEPTION",
    status: Status.ACTIVE,
  },
  {
    id: "ecd372e4-2a23-44e4-a4e2-2ee556cc8020",
    name: DirectToDoctorPrimarySymptomOption.GENITAL_INFECTIONS,
    friendlyName: "GENITAL_INFECTIONS",
    status: Status.ACTIVE,
  },
  {
    id: "4b00a459-72e5-4c7e-bd46-3d63e129078b",
    name: DirectToDoctorPrimarySymptomOption.GENERAL_CONDITIONS,
    friendlyName: "GENERAL_CONDITIONS",
    status: Status.ACTIVE,
  },
];

const pathologyPanels: PathologyPanel[] = [
  {
    panelId: "5fd39c79-26ca-4635-b86a-81500d3343f7",
    panelName: "Diabetes monitoring",
    amountTotal: 470.0,
    amountTotalFormatted: "R470",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.DIABETES_MONITORING,
    panelDescription: "This is the initial description of the Panel Diabetes",
    status: Status.ACTIVE,
    orderIndex: 15,
    created: "2024-11-20T07:31:27.441683+00:00",
    panelPathologyTests: [
      {
        testName: "Triglycerides",
        testAmount: 62.0,
        testCode: "TG",
      },
      {
        testName: "HbA1C",
        testAmount: 134.0,
        testCode: "HBA",
      },
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
      {
        testName: "HDL",
        testAmount: 129.0,
        testCode: "HDL",
      },
      {
        testName: "Total Cholesterol",
        testAmount: 52.0,
        testCode: "CH",
      },
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
    ],
  },
  {
    panelId: "7d87b969-5991-4664-b33b-dd4b7db5d306",
    panelName: "Hypertension monitoring",
    amountTotal: 165.0,
    amountTotalFormatted: "R165",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.BLOOD_PRESSURE_MONITORING,
    panelDescription:
      "This is the initial description of the Panel Hypertension",
    status: Status.ACTIVE,
    orderIndex: 16,
    created: "2024-11-20T07:31:27.441683+00:00",
    panelPathologyTests: [
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Potassium",
        testAmount: 36.0,
        testCode: "K",
      },
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
      {
        testName: "Total Cholesterol",
        testAmount: 52.0,
        testCode: "CH",
      },
    ],
  },
  {
    panelId: "cfede163-23dd-4f75-b3f6-ae42cb8310ec",
    panelName: "Hyperlipidemia monitoring",
    amountTotal: 336.0,
    amountTotalFormatted: "R336",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.CHOLESTEROL_MONITORING,
    panelDescription:
      "This is the initial description of the Panel Hyperlipidemia",
    status: Status.ACTIVE,
    orderIndex: 17,
    created: "2024-11-20T07:31:27.441683+00:00",
    panelPathologyTests: [
      {
        testName: "HDL",
        testAmount: 129.0,
        testCode: "HDL",
      },
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Triglycerides",
        testAmount: 62.0,
        testCode: "TG",
      },
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
      {
        testName: "Total Cholesterol",
        testAmount: 52.0,
        testCode: "CH",
      },
    ],
  },
  {
    panelId: "00eb8796-ac37-496e-aecc-1fac68ed2211",
    panelName: "CD4 & Viral Load",
    amountTotal: 701.0,
    amountTotalFormatted: "R701",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.CD4_VIRAL_LOAD,
    panelDescription:
      "This is the initial description of the Panel CD4 & Viral Load",
    status: Status.ACTIVE,
    orderIndex: 12,
    created: "2024-10-18T07:57:15.491446+00:00",
    panelPathologyTests: [
      {
        testName: "CD4",
        testAmount: 160.0,
        testCode: "CD4CD8",
      },
      {
        testName: "HIV VIRAL LOAD",
        testAmount: 541.0,
        testCode: "HIVVL",
      },
    ],
  },
  {
    panelId: "b49744ab-3a82-4ccc-b49c-614f49bb307c",
    panelName: "HbA1c",
    amountTotal: 134.0,
    amountTotalFormatted: "R134",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.HBA1C,
    panelDescription: "This is the initial description of the Panel HbA1c",
    status: Status.ACTIVE,
    orderIndex: 14,
    created: "2024-10-18T07:57:15.491446+00:00",
    panelPathologyTests: [
      {
        testName: "HbA1C",
        testAmount: 134.0,
        testCode: "HBA",
      },
    ],
  },
  {
    panelId: "c9759cb8-052a-4f01-9ec1-81342898e0d9",
    panelName: "Antenatal",
    amountTotal: 165.0,
    amountTotalFormatted: "R165",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.ANTENATAL,
    panelDescription: "This is the initial description of the Panel Antenatal",
    status: Status.ACTIVE,
    orderIndex: 13,
    created: "2024-10-18T07:57:15.491446+00:00",
    panelPathologyTests: [
      {
        testName: "HCG Quantitative",
        testAmount: 165.0,
        testCode: "HCGQ",
      },
    ],
  },
  {
    panelId: "122bb0ff-cd68-402e-a6ce-7f66cacc869a",
    panelName: "STD Screening (excl HIV)",
    amountTotal: 928.0,
    amountTotalFormatted: "R928",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.STD_SCREENING_EXCL_HIV,
    panelDescription:
      "This is the initial description of the Panel STD Screening (excl HIV)",
    status: Status.ACTIVE,
    orderIndex: 6,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 618.0,
        testCode: "STDPCR",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 155.0,
        testCode: "HEPBSAG",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 155.0,
        testCode: "RPR",
      },
    ],
  },
  {
    panelId: "162fa0d5-525d-4b06-b696-1bc0a9aab8dc",
    panelName: "Syphilis",
    amountTotal: 155.0,
    amountTotalFormatted: "R155",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.SYPHILIS,
    panelDescription: "This is the initial description of the Panel Syphilis",
    status: Status.ACTIVE,
    orderIndex: 8,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 155.0,
        testCode: "RPR",
      },
    ],
  },
  {
    panelId: "192878d4-0a7d-4481-b193-3382da78e5d3",
    panelName: "STD PCR",
    amountTotal: 618.0,
    amountTotalFormatted: "R618",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.STD_PCR,
    panelDescription: "This is the initial description of the Panel STD PCR",
    status: Status.ACTIVE,
    orderIndex: 7,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 618.0,
        testCode: "STDPCR",
      },
    ],
  },
  {
    panelId: "30cb47f4-a4c7-4a7c-8a61-adf446573997",
    panelName: "HIV Screening",
    amountTotal: 164.0,
    amountTotalFormatted: "R164",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.HIV_SCREENING,
    panelDescription:
      "This is the initial description of the Panel HIV Screening",
    status: Status.ACTIVE,
    orderIndex: 4,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "HIV Elisa",
        testAmount: 164.0,
        testCode: "HIV",
      },
    ],
  },
  {
    panelId: "488cf288-44dc-4a7a-9f16-e878e71ba9ef",
    panelName: "Hepatitis B",
    amountTotal: 155.0,
    amountTotalFormatted: "R155",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.HEPATITIS_B,
    panelDescription:
      "This is the initial description of the Panel Hepatitis B",
    status: Status.ACTIVE,
    orderIndex: 9,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Hep B surface antigen",
        testAmount: 155.0,
        testCode: "HEPBSAG",
      },
    ],
  },
  {
    panelId: "57e57183-884d-4843-be4a-fbbaeb61f623",
    panelName: "Male Over 45",
    amountTotal: 501.0,
    amountTotalFormatted: "R501",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.ANAEMIA,
    panelDescription:
      "This is the initial description of the Panel Male Over 45",
    status: Status.ACTIVE,
    orderIndex: 3,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "PSA",
        testAmount: 124.0,
        testCode: "PSA",
      },
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
      {
        testName: "TSH",
        testAmount: 165.0,
        testCode: "TSH",
      },
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
    ],
  },
  {
    panelId: "5ae2adc4-3a5c-4e35-80a8-ef0a04a26477",
    panelName: "Aneamia",
    amountTotal: 242.0,
    amountTotalFormatted: "R242",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.ANAEMIA,
    panelDescription: "This is the initial description of the Panel Aneamia",
    status: Status.ACTIVE,
    orderIndex: 10,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "FBC",
        testAmount: 93.0,
        testCode: "FBC",
      },
      {
        testName: "Ferritin",
        testAmount: 149.0,
        testCode: "FER",
      },
    ],
  },
  {
    panelId: "8406d19f-fe2a-4273-8540-c9d7b4ec157b",
    panelName: "Male Under 45",
    amountTotal: 377.0,
    amountTotalFormatted: "R377",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.MALE_UNDER_45,
    panelDescription:
      "This is the initial description of the Panel Male Under 45",
    status: Status.ACTIVE,
    orderIndex: 2,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
      {
        testName: "TSH",
        testAmount: 165.0,
        testCode: "TSH",
      },
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
    ],
  },
  {
    panelId: "865eb4bb-5b80-4988-9dfb-2d0423a4fc29",
    panelName: "HIV Monitoring",
    amountTotal: 820.0,
    amountTotalFormatted: "R820",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.HIV_MONITORING,
    panelDescription:
      "This is the initial description of the Panel HIV Monitoring",
    status: Status.ACTIVE,
    orderIndex: 11,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
      {
        testName: "CD4",
        testAmount: 160.0,
        testCode: "CD4CD8",
      },
      {
        testName: "HIV VIRAL LOAD",
        testAmount: 541.0,
        testCode: "HIVVL",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
    ],
  },
  {
    panelId: "950a8850-fdc4-4bd0-b64d-80f772de2a72",
    panelName: "STD Screening (incl HIV)",
    amountTotal: 1092.0,
    amountTotalFormatted: "R1092",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.STD_SCREENING_INCL_HIV,
    panelDescription:
      "This is the initial description of the Panel STD Screening (incl HIV)",
    status: Status.ACTIVE,
    orderIndex: 5,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 618.0,
        testCode: "STDPCR",
      },
      {
        testName: "HIV Elisa",
        testAmount: 164.0,
        testCode: "HIV",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 155.0,
        testCode: "RPR",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 155.0,
        testCode: "HEPBSAG",
      },
    ],
  },
  {
    panelId: "ccba2c3a-bbb5-4300-bfe9-c0e51a992d66",
    panelName: "Female",
    amountTotal: 377.0,
    amountTotalFormatted: "R377",
    unuHandlingFeeAmountFormatted: "R42",
    unuTransactionalFeeAmountFormatted: "R9",
    panelTestsAmountFormatted: "R419",
    panelChargeCode: PathologyPanelChargeCode.FEMALE,
    panelDescription: "This is the initial description of the Panel Female",
    status: Status.ACTIVE,
    orderIndex: 1,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
      {
        testName: "TSH",
        testAmount: 165.0,
        testCode: "TSH",
      },
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
    ],
  },
];

export default function ItemPillSliderExamples() {
  return (
    <>
      <Stack>
        <Typography variant="h2">Direct To Doctor</Typography>
        <ItemPillSlider
          itemMap={PRIMARY_SYMPTOM_PILL_MAP}
          items={primarySymptoms}
          onItemClick={() => console.log("On click")}
        />
        <Typography variant="h2">Direct To Blood</Typography>
        <ItemPillSlider
          itemMap={PATHOLOGY_PANEL_PILL_MAP}
          items={pathologyPanels}
          onItemClick={() => console.log("On click")}
        />
      </Stack>
    </>
  );
}
