import {
  AnalyticsLinkIntent,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import { ButtonProps, Button as MuiButton } from "@mui/material";

interface ButtonWithAnalyticsProps extends ButtonProps {
  page?: string;
  text?: string;
  scope?: string;
  intent?: AnalyticsLinkIntent;
  target?: string;
  onClick?: () => void;
}

export default function ButtonWithAnalytics({
  page,
  text,
  scope = "button",
  intent,
  target,
  ...props
}: ButtonWithAnalyticsProps) {
  function onButtonClick() {
    if (page && text && intent) {
      const transformedEventText = `${page} | ${text}`;
      trackUserInteraction({
        linkText: transformedEventText,
        linkIntent: intent,
        linkScope: scope,
      });
    }
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <MuiButton
      {...props}
      {...(props.href ? { target } : {})}
      onClick={onButtonClick}
    />
  );
}
