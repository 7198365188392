import WhatsappBreakout from "@/features/Common/components/WhatsappBreakout";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import { buildWhatsAppUniversalLinkURL } from "@/utils";
import { useTranslation } from "react-i18next";

interface PathologyResultsBreakoutProps {
  onBackButtonClick: () => void;
}

export default function PathologyResultsBreakout({
  onBackButtonClick,
}: PathologyResultsBreakoutProps) {
  const { t } = useTranslation();

  const provider = "Ampath";

  const CHAT_BOT_WHATSAPP_PHONE_NUMBER = getConfigurationVariable(
    "VITE_APP_AMPATH_CHAT_BOT_PHONE_NUMBER_ZA"
  );

  function prefilledMessage(provider: string): string {
    return t(`WhatappBreakout.${provider}.prefilledMessage` as any);
  }
  function getWhatsappBreakoutURL() {
    return buildWhatsAppUniversalLinkURL(
      CHAT_BOT_WHATSAPP_PHONE_NUMBER,
      prefilledMessage(provider)
    );
  }

  return (
    <WhatsappBreakout
      onBackButtonClick={onBackButtonClick}
      url={getWhatsappBreakoutURL()}
      provider={provider}
      logo="/assets/MedicalAssistancePartners/Ampath/logo-color.svg"
    />
  );
}
