import CircledBackButton from "@/features/Common/components/CircledBackButton";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import IconLoader from "@/features/Common/components/IconLoader";
import Panel from "@/features/Common/components/Panel";
import useGetPathologyOrders from "@/features/Common/hooks/useGetPathologyOrders";
import { PathologyOrder } from "@/services/core-api-adapter";
import theme, { defaultFullscreenPageStyling } from "@/theme";
import { formatDateToReadableString } from "@/utils";
import { Divider, ListItem, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";

interface PurchasePanelProps {
  order: PathologyOrder;
  onClick: () => void;
}

function PurchasePanel({ order, onClick }: PurchasePanelProps) {
  return (
    <Stack
      component="button"
      aria-label={`orderNumber-${order.orderNumber}`}
      onClick={onClick}
      sx={{
        background: theme.palette.background.paper,
        cursor: "pointer",
        border: "none",
        width: "100%",
        display: "block",
      }}
    >
      <Panel>
        <Stack spacing={2}>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            textAlign="left"
          >
            <Stack spacing={0.5}>
              <Typography variant="body2" fontWeight="bold" color="neutral.500">
                {formatDateToReadableString(new Date(order.created))}
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {t("PathologyOrderSummary.orderNumber.title")}
              </Typography>
              <Typography variant="body2">{order.orderNumber}</Typography>
            </Stack>
            <IconLoader icon="ChevronNextIcon" />
          </Stack>

          <Divider />

          <Typography variant="h3" textAlign="left">
            <Trans
              i18nKey="MyPurchases.orderDetails.total"
              values={{
                count: order.orderPriceFormatted,
              }}
            >
              <Typography
                component="span"
                variant="h3"
                fontWeight="bold"
                color="primary"
              ></Typography>
            </Trans>
          </Typography>
        </Stack>
      </Panel>
    </Stack>
  );
}

export default function MyPurchases() {
  const navigate = useNavigate();
  const { ordersList, ordersFetchError, isOrdersLoading } =
    useGetPathologyOrders();

  if (isOrdersLoading) {
    return <FullscreenLoadingIndicator />;
  }

  function onPurchasePanelClick(data: PathologyOrder) {
    navigate(`/home/get-medical-assistance/pathology?orderId=${data.orderId}`);
  }

  function onBackButtonClick() {
    navigate("/home/more");
  }

  return (
    <Stack
      sx={{
        ...defaultFullscreenPageStyling,
      }}
      spacing={2}
    >
      <CircledBackButton showLabel={true} onClick={onBackButtonClick} />

      <Typography
        component="h1"
        variant="h2"
        sx={{
          mb: 1,
        }}
      >
        {t("MyPurchases.title")}
      </Typography>

      {!isOrdersLoading && ordersFetchError && (
        <Typography variant="body1" align="left">
          {t("common.somethingWentWrong")}
        </Typography>
      )}

      {!isOrdersLoading && !ordersFetchError && ordersList && (
        <Stack
          component="ul"
          role="list"
          aria-label={t("MyPurchases.title")}
          spacing={2}
        >
          {ordersList.map((order) => (
            <ListItem
              key={order.orderId}
              role="listitem"
              sx={{
                padding: 0,
              }}
            >
              <PurchasePanel
                order={order}
                onClick={() => onPurchasePanelClick(order)}
              />
            </ListItem>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
